import React, { useEffect, useState } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { Jumbotron, Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import DateInput from '../../components/form/DateInput.js';
import { ApiUrl, ApiKey, Images, PoolType } from "../../util/Constant.js";
import moment from 'moment';
import { createFormBody, formatNumber } from '../../util/Utility.js';
import { useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

const DailySales = props => {
    var { t } = useTranslation();
    const _dispatch = useDispatch();

    const [selectedPool, setSelectedPool] = useState(PoolType._ALL_POOL);
    const [targetDate, setTargetDate] = useState(new Date());
    const [totalSales, setTotalSales] = useState('0.00');
    const [reportDetails, setReportDetails] = useState([]);
    const _REPORT_OPTION = [
        { label: 'ALL', value: PoolType._ALL_POOL },
        { label: 'NORMAL_POOL', value: PoolType._NORMAL_POOL },
        { label: 'GD_POOL', value: PoolType._GD_POOL },
        { label: 'NINE_LOTTO', value: PoolType._NINE_LOTTO_POOL },
    ];

    useEffect(() => {
        getDailySalesReport()
    }, []);

    async function getDailySalesReport() {
        let params = {
            date_from: encodeURIComponent(moment(targetDate).format('YYYYMMDD')),
            report_type: selectedPool
        }

        _dispatch(setBusy());
        await ApiEngine.post(ApiUrl._API_GET_DAILY_SALES, createFormBody(params))
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson['data']
                    setTotalSales(data['total']);
                    var userDetails = [];

                    for (var i = 0; i < data['detail_lst'].length; i++) {
                        var details = data['detail_lst'][i].split('\r\n');
                        var userSales = '';
                        if (details.length > 2) {
                            for (var j = 1; j < details.length; j++) {
                                // Prevent white space insert into last row
                                if (details[j] != "") {
                                    userSales += details[j] + '\r\n';
                                }
                            }
                        }
                        else {
                            userSales = 'No Record' + "\r\n";
                        }
                        details = {
                            name: details[0],
                            sales: userSales
                        };
                        userDetails.push(details);
                    }

                    setReportDetails(userDetails);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
        _dispatch(setIdle());
    };

    return (
        <>
            <PageNav pageTitle={t("DAILY_SALES")} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div className="set-page-content">
                <div className="page-container container">
                    <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper" style={{ marginBottom: ".7rem" }}>
                        <div>
                            <img src={Images._CLOCK_ICON} />
                            <div className="date-wrapper">
                                <span className="m-0">{t("DRAW_DATE")}</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={moment(targetDate).toDate()}
                                    onChange={setTargetDate}
                                    placeholderText="dd/mm/yyyy"
                                    popperPlacement="bottom-start"
                                    customInput={<DateInput />}
                                />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("POOL")}</span>
                                <Input className="form-control form-control-transparent" type="select" value={selectedPool} onChange={(e) => { setSelectedPool(e.target.value); }}>
                                    {
                                        _REPORT_OPTION.map((b) => {
                                            return <option value={b.value}>{t(b.label)}</option>
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                        <Button className="theme-btn-2 pull-right" onClick={() => getDailySalesReport()}>{t('APPLY')}</Button>
                    </Jumbotron>
                    <div className="mb-2" style={{ textAlign: 'center' }}>
                        <span className="badge badge-brand badge-total-eat">
                            {t('TOTAL')} : <strong>{formatNumber(totalSales, 2)}</strong>
                        </span>
                    </div>
                    {
                        reportDetails.length == 0 &&
                        <div className="empty-state-text">{t("NO_RESULT_FOUND")}</div>
                    }
                    <div className="bet-report-list">
                        {
                            reportDetails.map((item, index) => {
                                return (
                                    <div className="bet-report-item" key={index}>
                                        <div className="d-flex align-items-center"><strong>{item.name}</strong></div>
                                        <div className="d-flex align-items-center">{item.sales}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default DailySales;