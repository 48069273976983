import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ApiUrl, ApiKey, WebUrl } from '../../util/Constant';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

const BettingStateModal = props => {
    const _classname = "bettingModal"
    const _history = useHistory();
    const { isModalOpen, setModalOpen, fromConfirmationFooter, betId, lastDateRange } = props;
    const toggle = () => setModalOpen(!isModalOpen);
    const _dispatch = useDispatch();
    var { t } = useTranslation();

    async function cancelOrder() {
        _dispatch(setBusy());
        let url = ApiUrl._API_CANCEL_ORDER;
        url += `?header_id=${betId}`;
        await ApiEngine.get(url)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    if (props.onCancelDone) {
                        props.onCancelDone();
                    }
                    else {
                        _history.push(WebUrl._CANCEL_SUCCESSFUL, { lastDateRange });
                    }
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            }).finally(() => {
                _dispatch(setIdle());
            })
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggle} className={_classname} centered>
            <ModalBody className="text-center">
                <p className="f-s-20 sfp-bold"> {t("CONFIRM_CANCEL_ORDER")} </p>
                <p className="f-s-17 theme-text-3 sfp-medium">{t("CONFIRM_CANCEL_ORDER_LINE1")} <br />{t("CONFIRM_CANCEL_ORDER_LINE2")}</p>
                <div className="d-flex justify-content-center">
                    <Button onClick={toggle} className="theme-btn-4 btn btn-md cancel-btn m-r-5">{t("NO")}</Button>
                    <Button onClick={() => {
                        cancelOrder();
                    }} className="theme-btn-1 btn btn-md m-l-5">{t("YES")}</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default BettingStateModal;