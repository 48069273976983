import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { ApiUrl, ApiKey } from '../../util/Constant';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { performLogout } from '../../redux/AuthAction';
import { showJsonResponseAlert, setBusy, setIdle } from '../../redux/AppAction';
import { useForm, Controller } from 'react-hook-form';
import Input from '../form/Input';
import ApiEngine from '../../util/ApiEngine';
import { createMultiPartFormBody } from '../../util/Utility';

const ChangePasswordModal = props => {
    var { t } = useTranslation();
    const _classname = "bettingModal";
    const _dispatch = useDispatch();
    const _history = useHistory();
    const { isModalOpen, setModalOpen } = props;
    const toggle = () => setModalOpen(!isModalOpen);
    const { control, handleSubmit, errors } = useForm();

    /// <summary>
    /// Author: -
    /// </summary>
    const submitForm = async (data, e) => {
        _dispatch(setBusy());
        let formCollections = {
            oldPassword: data.oldPsw,
            newPassword: data.newPsw
        };
        await ApiEngine.post(ApiUrl._API_CHANGE_PASSWORD, createMultiPartFormBody(formCollections))
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    toggle();
                    _dispatch(performLogout());
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
        _dispatch(setIdle());
    }

    return (
        <Modal isOpen={isModalOpen} toggle={toggle} className={_classname} centered>
            <ModalBody className="text-center">
                <form onSubmit={handleSubmit(submitForm)}>
                    <p className="f-s-20 sfp-bold">{t("CHANGE_PASSWORD")}</p>
                    <p className="f-s-17 theme-text-3 sfp-medium">
                        <span>{t("OLD_PASSWORD")}</span>
                        <Controller
                            control={control}
                            name="oldPsw"
                            render={({ onChange, value, name }) => (
                                <Input
                                    name={name}
                                    className="form-control"
                                    value={value}
                                    onChange={value => {
                                        onChange(value);
                                    }}
                                    placeholder={t('OLD_PASSWORD')}
                                    autocomplete="off" />
                            )}
                            rules={{ required: true }}
                        />
                    </p>
                    <p className="f-s-17 theme-text-3 sfp-medium">
                        <span>{t("NEW_PASSWORD")}</span>
                        <Controller
                            control={control}
                            name="newPsw"
                            render={({ onChange, value, name }) => (
                                <Input
                                    name={name}
                                    className="form-control"
                                    value={value}
                                    onChange={value => {
                                        onChange(value);
                                    }}
                                    placeholder={t('NEW_PASSWORD')}
                                    autocomplete="off" />
                            )}
                            rules={{ required: true }}
                        />
                    </p>
                    <div className="d-flex justify-content-center">
                        <Button onClick={toggle} className="theme-btn-4 btn btn-md cancel-btn m-r-5">{t("CANCEL")}</Button>
                        <Button type='submit' className="theme-btn-1 btn btn-md m-l-5">{t("SUBMIT")}</Button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    )
}

export default ChangePasswordModal;