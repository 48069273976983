import React, { useState, useEffect } from "react";
import {
    Jumbotron, Row, Col, Button,
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import PageNav from '../../components/header/page-navbar.jsx';
import DatePicker from "react-datepicker";
import classnames from 'classnames';
import DateInput from '../../components/form/DateInput';

import { ApiUrl, ApiKey, Images, WebUrl } from "../../util/Constant.js";
import moment from 'moment';
import { stringIsNullOrEmpty, formatNumber } from '../../util/Utility.js';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import DateRangeFilter from '../../components/date-picker/DateRangeFilter';
import ApiEngine from '../../util/ApiEngine';

/// <summary>
/// Author: -
/// </summary>
const WalletStatement = props => {
    var { t } = useTranslation();
    const _history = useHistory();
    const _dispatch = useDispatch();

    const [statements, setStatements] = useState([]);

    async function getWalletStatement(startDate, endDate) {
        let url = ApiUrl._API_GET_WALLET_STATEMENT + "?fromDate=" + moment(startDate).format("MM/DD/yyyy") + "&toDate=" + moment(endDate).format("MM/DD/yyyy");

        _dispatch(setBusy());

        await ApiEngine.get(url).then((responseJson) => {
                _dispatch(setIdle());
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setStatements(responseJson[ApiKey._API_DATA_KEY]);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    return (
        <>
            <PageNav pageTitle={t('WALLET_STATEMENT')} pageBackHandler={() => { _history.push(WebUrl._HOME) }} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div id="bettingHistory" className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <DateRangeFilter onSave={(start, end) => { getWalletStatement(start, end) }} options />
                            <table className="table table-wallet-statements">
                                <thead>
                                    <tr>
                                        <th>{t("DATE")}</th>
                                        <th>{t("REMARK")}</th>
                                        <th>{t("PRE_BALANCE")}</th>
                                        <th>{t("AMOUNT")}</th>
                                        <th>{t("POST_BALANCE")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        statements.map((stmt) => {
                                            return <tr>
                                                <td>{stmt.formattedDate.replace(" ", "\n")}</td>
                                                <td>{stmt.remark}</td>
                                                <td>{formatNumber(stmt.preBalance, 2)}</td>
                                                <td className={stmt.amount > 0 ? 'text-green' : 'text-red'}>{formatNumber(stmt.amount, 2)}</td>
                                                <td>{formatNumber(stmt.postBalance, 2)}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletStatement;