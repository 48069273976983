import React, { useEffect, useState } from 'react';
import PageNav from '../../components/header/page-navbar.jsx';
import { Jumbotron, Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import DateInput from '../../components/form/DateInput.js';
import { ApiUrl, ApiKey, Images, PoolType } from "../../util/Constant.js";
import moment from 'moment';
import { createFormBody, formatNumber, isObjectEmpty } from '../../util/Utility.js';
import { useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import ApiEngine from '../../util/ApiEngine';

const DailyProfileLoss = props => {
    var { t } = useTranslation();
    const _dispatch = useDispatch();

    const [selectedPool, setSelectedPool] = useState(PoolType._ALL_POOL);
    const [selectedFromDate, setSelectedFromDate] = useState(moment().format('YYYY-MM-DD'));
    const [selectedToDate, setSelectedToDate] = useState(moment().format('YYYY-MM-DD'));
    const [downlineTotal, setDownlineTotal] = useState('0.00');
    const [uplineTotal, setUplineTotal] = useState('0.00');
    const [netProfit, setNetProfit] = useState('0.00');
    const [reportDetails, setReportDetails] = useState([]);
    const _REPORT_OPTION = [
        { label: 'ALL', value: PoolType._ALL_POOL },
        { label: 'NORMAL_POOL', value: PoolType._NORMAL_POOL },
        { label: 'GD_POOL', value: PoolType._GD_POOL },
        { label: 'NINE_LOTTO', value: PoolType._NINE_LOTTO_POOL },
    ];

    useEffect(() => {
        getProfitLossReport()
    }, []);

    async function getProfitLossReport() {
        let params = {
            date_from: encodeURIComponent(moment(selectedFromDate).format('YYYYMMDD')),
            date_to: encodeURIComponent(moment(selectedToDate).format('YYYYMMDD')),
            report_type: selectedPool
        }

        _dispatch(setBusy());
        await ApiEngine.post(ApiUrl._API_GET_DAILY_PROFIT_LOSS, createFormBody(params))
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    setReportDetails(responseJson['data']);
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
        _dispatch(setIdle());
    };

    return (
        <>
            <PageNav pageTitle={t("PROFIT_LOSS_2")} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
            </div>
            <div className="set-page-content">
                <div className="page-container container">
                    <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper" style={{ marginBottom: ".7rem" }}>
                        <div>
                            <img src={Images._CLOCK_ICON} />
                            <div className="date-wrapper">
                                <span className="m-0">{t("FROM_DRAWDATE")}</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={moment(selectedFromDate).toDate()}
                                    onChange={(date) => {
                                        if (moment(selectedToDate) < moment(date)) {
                                            setSelectedToDate(date);
                                        }
                                        setSelectedFromDate(date);
                                    }}
                                    placeholderText="dd/mm/yyyy"
                                    popperPlacement="bottom-start"
                                    customInput={<DateInput />}
                                />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("TO_DRAWDATE")}</span>
                                <DatePicker
                                    dateFormat="dd/MM/yyyy"
                                    selected={moment(selectedToDate).toDate()}
                                    onChange={(date) => {
                                        if (moment(selectedFromDate) > moment(date)) {
                                            setSelectedFromDate(date);
                                        }
                                        setSelectedToDate(date);
                                    }}
                                    placeholderText="dd/mm/yyyy"
                                    popperPlacement="bottom-start"
                                    customInput={<DateInput />}
                                />
                            </div>
                            <div className="date-wrapper">
                                <span className="m-0">{t("POOL")}</span>
                                <Input className="form-control form-control-transparent" type="select" value={selectedPool} onChange={(e) => { setSelectedPool(e.target.value); }}>
                                    {
                                        _REPORT_OPTION.map((b) => {
                                            return <option value={b.value}>{t(b.label)}</option>
                                        })
                                    }
                                </Input>
                            </div>
                        </div>
                        <Button className="theme-btn-2 pull-right" onClick={() => getProfitLossReport()}>{t('APPLY')}</Button>
                    </Jumbotron>
                    <div className="mb-2" style={{ textAlign: 'center' }}>
                        <span>
                            {`${moment(selectedFromDate).format('DD/MM/YYYY')} - ${moment(selectedToDate).format('DD/MM/YYYY')}`}
                        </span>
                    </div>
                    {
                        !isObjectEmpty(reportDetails) ?
                            <>
                                <div className="bet-report-item">
                                    <div className="d-flex align-items-center">T.BUY: <strong>{reportDetails.buy_total}</strong></div>
                                    <div className="d-flex align-items-center">T.COMM: <strong>{reportDetails.comm_total}</strong></div>
                                    <div className="d-flex align-items-center">T.WIN: <strong>{reportDetails.win_total}</strong></div>
                                    <div className="d-flex align-items-center">T.NETT: <strong>{reportDetails.nett_total}</strong></div>
                                </div>
                                <br />
                                <br />
                                {
                                    reportDetails.detail_list.map((item, index) => {
                                        return (
                                            <div className="bet-report-item" key={index}>
                                                <div className="d-flex align-items-center"><strong>{item.drawDate}</strong></div>
                                                <div className="d-flex align-items-center">===================</div>
                                                <div className="d-flex align-items-center">BUY: <strong>{formatNumber(item.buy, 2)}</strong></div>
                                                <div className="d-flex align-items-center">COMM: <strong>{formatNumber(item.comm, 2)}</strong></div>
                                                <div className="d-flex align-items-center">WIN: <strong>{formatNumber(item.win, 2)}</strong></div>
                                                <div className="d-flex align-items-center">NETT: <strong>{formatNumber(item.nett, 2)}</strong></div>
                                            </div>
                                        )
                                    })
                                }
                            </> :
                            <div className="empty-state-text">{t("NO_RESULT_FOUND")}</div>
                    }
                </div>
            </div>
        </>
    )
}

export default DailyProfileLoss;