import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Images, POOL_SHORT_CODE, APP_TYPE } from '../../util/Constant';
import classname from 'classnames';
import { AppConfiguration } from "read-appsettings-json";

const _BETTING_BRANDS_SWIPER = [
    { poolId: 0, shortCode: POOL_SHORT_CODE._MAGNUM, imgSrc: Images._MAGNUM_LOGO_SQUARE, active: false },
    { poolId: 1, shortCode: POOL_SHORT_CODE._DAMACAI, imgSrc: Images._DAMACAI_INVERSED_LOGO, active: false },
    { poolId: 2, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: Images._TOTO_INVERSED_LOGO, active: false },
    { poolId: 3, shortCode: POOL_SHORT_CODE._SG, imgSrc: Images._SG_LOTTO_SQUARE, active: false },
    { poolId: 4, shortCode: POOL_SHORT_CODE._88, imgSrc: Images._88_LOTTO_LOGO_SQUARE, active: false },
    //{ poolId: 5, shortCode: POOL_SHORT_CODE._STC, imgSrc: Images._STC_LOTTO_LOGO, active: false },
    { poolId: 6, shortCode: POOL_SHORT_CODE._CASH_SWEEP, imgSrc: Images._DA_CASH_WAN_LOGO, active: false },
    { poolId: 7, shortCode: POOL_SHORT_CODE._GD, imgSrc: Images._GD_LOTTO_LOGO, active: false },
    { poolId: 8, shortCode: POOL_SHORT_CODE._NINELOTTO, imgSrc: Images._NINELOTTO_LOGO, active: false },
];

const _BETTING_BRANDS_SWIPER_6D = [
    { poolId: 2, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: Images._TOTO_INVERSED_LOGO, active: true },
    { poolId: 7, shortCode: POOL_SHORT_CODE._GD, imgSrc: Images._GD_LOTTO_LOGO, active: false },
    { poolId: 8, shortCode: POOL_SHORT_CODE._NINELOTTO, imgSrc: Images._NINELOTTO_LOGO, active: false },
];

const _BETTING_BRANDS_SWIPER_5D = [
    { poolId: 2, shortCode: POOL_SHORT_CODE._TOTO, imgSrc: Images._TOTO_INVERSED_LOGO, active: true }
];

/// <summary>
/// Author: -
/// </summary>
const PoolPickSwiper = props => {
    const {
        selectedPoolId,
        setSelectedPoolId,
        betType,
        calculate
    } = props;

    const [poolActive, setPoolActive] = useState([]);
    const [finalPoolList, setFinalPoolList] = useState([]);

    const toggleIconHandler = (poolId) => {
        var newArr = []
        poolActive.map((item, index) => {
            if (item.poolId === poolId) {
                item.active = !item.active
            }
            newArr.push(item)
        })
        setPoolActive(newArr);
        setSelectedPoolId(newArr);
        calculate();
    }

    useEffect(() => {
        let poolList = _BETTING_BRANDS_SWIPER;
        poolList[0].active = true;

        if (betType == "6D") {
            setFinalPoolList(_BETTING_BRANDS_SWIPER_6D);
            setPoolActive(_BETTING_BRANDS_SWIPER_6D);
            setSelectedPoolId(_BETTING_BRANDS_SWIPER_6D);
        }
        else if (betType == "5D") {
            setFinalPoolList(_BETTING_BRANDS_SWIPER_5D);
            setPoolActive(_BETTING_BRANDS_SWIPER_5D);
            setSelectedPoolId(_BETTING_BRANDS_SWIPER_5D);
        }
        else {
            if (betType == "2D") {
                poolList = poolList.filter(x => x.shortCode != POOL_SHORT_CODE._GD);
            }
            
            setPoolActive(poolList);
            setSelectedPoolId(poolList);
            setFinalPoolList(poolList);
        }
    }, [betType])

    return (
        finalPoolList.length > 0 &&
        <div className="pool-picker-wrapper">
            {
                finalPoolList.map((item, index) => {
                    return <div key={item.poolId} className={classname("text-center pool-selection", { "active": item.active })}
                        onClick={() => { toggleIconHandler(item.poolId) }}>
                        <img className="brand-icon" src={item.imgSrc}></img>
                    </div>
                })
            }
        </div>
    )
}

export default PoolPickSwiper;
