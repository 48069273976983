import React, { useState, useEffect } from 'react';
import { Form, FormGroup, Input, Label, Table } from 'reactstrap';
import PageNav from '../../components/header/page-navbar.jsx';
import { WebUrl, ApiUrl, ApiKey, PACKAGE_BET_TYPES } from '../../util/Constant';
import { useDispatch } from "react-redux";
import { showJsonResponseAlert } from "../../redux/AppAction";
import { useTranslation } from 'react-i18next';
import { AppConfiguration } from "read-appsettings-json";
import ApiEngine from '../../util/ApiEngine';

const _DEFAULT_DATA =
{
    activatedPackage: "",
    commissionList2d: [],
    commissionList3d: [],
    commissionList4d: [],
    commissionList5d: [],
    commissionList6d: [],
    payoutList2d: [],
    payoutList3d: [],
    payoutList4d: [],
    payoutList5d: [],
    payoutList6d: []
}

/// <summary>
/// Author: -
/// </summary>
const PackagesList = props => {

    const _dispatch = useDispatch();
    const [packageList, setPackageList] = useState(_DEFAULT_DATA);
    const [appType, setAppType] = useState(AppConfiguration.Setting().ApplicationConfig.appType);
    const [packageBetType, setPackageBetType] = useState(PACKAGE_BET_TYPES.filter(p => p.appType.includes(appType)));
    var { t } = useTranslation();

    useEffect(() => {
        getPackageList();
    }, [])

    /// <summary>
    /// Author: Eastnoname
    /// </summary>
    async function getPackageList() {
        let url = ApiUrl._API_GET_PACKAGE_LIST;
        await ApiEngine.get(url).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY]) {
                    setPackageList(responseJson[ApiKey._API_DATA_KEY]);
                    props.activatedPackage(responseJson[ApiKey._API_DATA_KEY].activatedPackage)
                }
            });
    }

    function makeViewMaps(commissionList, payoutList, typeName) {
        var typeRows = [];
        var commissionRows = [];
        var prize1Rows = [];
        var prize2Rows = [];
        var prize3Rows = [];
        var prize4Rows = [];
        var prize5Rows = [];
        var prize6Rows = [];
        var prizeSRows = [];
        var prizeCRows = [];


        typeRows.push(t('TYPE'));
        commissionRows.push("Commission");
        prize1Rows.push(t('FIRST_PRIZE'));
        prize2Rows.push(t('SECOND_PRIZE'));
        prize3Rows.push(t('THIRD_PRIZE'));
        prize4Rows.push(t('FORTH_PRIZE'));
        prize5Rows.push(t('FIFTH_PRIZE'));
        prize6Rows.push(t('SIXTH_PRIZE'));
        prizeSRows.push(t('STARTER'));
        prizeCRows.push(t('CONSOLATION'));

        packageBetType.map((d, dind) => {
            if (d.name === typeName) {
                d.types.map((typ, typind) => {
                    typeRows.push(typ);
                    commissionList.map((comm, commind) => {
                        if (comm.betType === typ) {
                            commissionRows.push(comm.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "1st Prize") {
                            prize1Rows.push(pay.amount);
                        }
                    });


                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "2nd Prize") {
                            prize2Rows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "3rd Prize") {
                            prize3Rows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "4th Prize") {
                            prize4Rows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "5th Prize") {
                            prize5Rows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "6th Prize") {
                            prize6Rows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "Starter") {
                            prizeSRows.push(pay.amount);
                        }
                    });

                    payoutList.map((pay, payind) => {
                        if (pay.betType === typ && pay.priceName === "Consolation") {
                            prizeCRows.push(pay.amount);
                        }
                    });
                });
            }
        })

        var tableView =
        {
            typeRows: typeRows,
            commissionRows: commissionRows,
            prize1Rows: prize1Rows,
            prize2Rows: prize2Rows,
            prize3Rows: prize3Rows,
            prize4Rows: prize4Rows,
            prize5Rows: prize5Rows,
            prize6Rows: prize6Rows,
            prizeSRows: prizeSRows,
            prizeCRows: prizeCRows,
        }
        return tableView;
    }

    return (
        <div id="packagesList">
            {
                packageBetType.map((elem, index) => {
                    var theReturnView;
                    if (elem.name == "4D") {
                        theReturnView = makeViewMaps(packageList.commissionList4d, packageList.payoutList4d, "4D");
                    }
                    else if (elem.name == "3D") {
                        theReturnView = makeViewMaps(packageList.commissionList3d, packageList.payoutList3d, "3D");
                    }
                    else if (elem.name == "5D") {
                        theReturnView = makeViewMaps(packageList.commissionList5d, packageList.payoutList5d, "5D");
                    }
                    else if (elem.name == "6D") {
                        theReturnView = makeViewMaps(packageList.commissionList6d, packageList.payoutList6d, "6D");
                    }
                    else if (elem.name == "2D") {
                        theReturnView = makeViewMaps(packageList.commissionList2d, packageList.payoutList2d, "2D");
                    }
                    return (
                        <div className="package-tbl-wrapper x-scrollbar-1">
                            <Table borderless className="m-0">
                                <tbody>
                                    <tr className="row-type">
                                        {
                                            theReturnView.typeRows.map((e, i) => {
                                                return (
                                                    <>
                                                        <td>{e}</td>
                                                    </>)
                                            })
                                        }
                                    </tr>

                                    <tr className="row-deep">
                                        {
                                            theReturnView.prize1Rows.map((e, i) => {
                                                return (
                                                    <>
                                                        <td>{e}</td>
                                                    </>)
                                            })
                                        }
                                    </tr>
                                    <tr>
                                        {
                                            theReturnView.prize2Rows.map((e, i) => {
                                                return (
                                                    <>
                                                        <td>{e}</td>
                                                    </>)
                                            })
                                        }
                                    </tr>
                                    <tr className="row-deep">
                                        {
                                            theReturnView.prize3Rows.map((e, i) => {
                                                return (
                                                    <>
                                                        <td>{e}</td>
                                                    </>)
                                            })
                                        }
                                    </tr>
                                    {
                                        theReturnView.prize4Rows.filter(r => !isNaN(r) && r > 0).length > 0 &&
                                        <tr>
                                            {
                                                theReturnView.prize4Rows.map((e, i) => {
                                                    return (
                                                        <>
                                                            <td>{e}</td>
                                                        </>)
                                                })
                                            }
                                        </tr>
                                    }
                                    {
                                        theReturnView.prize5Rows.filter(r => !isNaN(r) && r > 0).length > 0 &&
                                        <tr className="row-deep">
                                            {
                                                theReturnView.prize5Rows.map((e, i) => {
                                                    return (
                                                        <>
                                                            <td>{e}</td>
                                                        </>)
                                                })
                                            }
                                        </tr>
                                    }
                                    {
                                        theReturnView.prize6Rows.filter(r => !isNaN(r) && r > 0).length > 0 &&
                                        <tr>
                                            {
                                                theReturnView.prize6Rows.map((e, i) => {
                                                    return (
                                                        <>
                                                            <td>{e}</td>
                                                        </>)
                                                })
                                            }
                                        </tr>
                                    }
                                    {
                                        theReturnView.prizeSRows.filter(r => !isNaN(r) && r > 0).length > 0 &&
                                        <tr className="row-deep">
                                            {
                                                theReturnView.prizeSRows.map((e, i) => {
                                                    return (
                                                        <>
                                                            <td>{e}</td>
                                                        </>)
                                                })
                                            }
                                        </tr>
                                    }
                                    {
                                        theReturnView.prizeCRows.filter(r => !isNaN(r) && r > 0).length > 0 &&
                                        <tr>
                                            {
                                                theReturnView.prizeCRows.map((e, i) => {
                                                    return (
                                                        <>
                                                            <td>{e}</td>
                                                        </>)
                                                })
                                            }
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default PackagesList;