import React, { useState } from 'react';
import { Route, withRouter, Link } from 'react-router-dom';
import { routes, unauthorizedRoute } from './../../config/page-route.jsx';
import { PageSettings } from './../../config/page-settings.js';
import { useEffect } from 'react';
import { AppConfiguration } from "read-appsettings-json";
import { APP_TYPE } from '../../util/Constant';
import { useSelector } from "react-redux";
import State from "./State";
import { useTranslation } from 'react-i18next';

const setTitle = (path, routeArray, setPageTitle) => {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path) {
            pageTitle = `${routeArray[i].title} | ${AppConfiguration.Setting().ApplicationConfig.appType == APP_TYPE._NORMAL ? "Lotto" : "GD"}`;
		}
	}
	document.title = (pageTitle) ? pageTitle : '';
}

const Content = ({ history }) => {
    var { t } = useTranslation();
    var _isLoggedIn = useSelector(state => state.authState.isLoggedIn);

	/// <summary>
	/// Author: -
	/// </summary>
    useEffect(() => {
		setTitle(history.location.pathname, routes);

		return history.listen(() => {
			setTitle(history.location.pathname, routes);
		});
	}, []);

	return (
		<PageSettings.Consumer>
            {({ pageContentFullWidth, pageContentClass, pageContentInverseMode, pageFooter}) => (
				<div className={'content ' + (pageContentFullWidth ? 'content-full-width ' : '') + (pageContentInverseMode ? 'content-inverse-mode ' : '') + pageContentClass + (pageFooter ? 'has-footer ' : '')}>
					{routes.map((route, index) => (
                        <Route
                            key={index}
                            exact={route.authRequired && !_isLoggedIn ? unauthorizedRoute.exact : route.exact}
                			path={route.authRequired && !_isLoggedIn ? unauthorizedRoute.path : route.path}
                			component={route.authRequired && !_isLoggedIn ? unauthorizedRoute.component : route.component}
						/>
					))}
				</div>
			)
		}
		</PageSettings.Consumer>
	)
}

export default withRouter(Content);
