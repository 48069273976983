import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Modal, ModalBody } from 'reactstrap';
import { WebUrl, BET_STATUS, SHARE_PLATFORM, ApiUrl, ApiKey } from '../../util/Constant';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showJsonResponseAlert, setBusy, setIdle } from "../../redux/AppAction";
import ApiEngine from '../../util/ApiEngine';
import { useDispatch } from "react-redux";

/// <summary>
/// Author: -
/// </summary>
const BetSuccessful = props => {
    const _history = useHistory();
    const _dispatch = useDispatch();
    var { t } = useTranslation();
    var _state = _history.location.state;
    const [receipt, setReceipt] = useState();
    const [shareModal, setShareModal] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [cancelledTicket, setCancelledTicket] = useState([]);
    const [selectedTicket, setSelectedTicket] = useState([]);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (_state) {
            setReceipt(_state.receipt);
        } else {
            _history.push('/');
        }
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    const renderBetStatus = (betStatus) => {
        switch (betStatus) {
            case BET_STATUS.COMPLETE:
                return <div className="alert bg-green-gradient p-5">{t("COMPLETE_DESC")}</div>
                break;
            case BET_STATUS.LM:
                return <div className="alert bg-yellow-gradient p-5">{t("LIMIT_DESC")}</div>
                break;
            case BET_STATUS.SO:
                return <div className="alert bg-red-gradient p-5">{t("SOLD_OUT_DESC")}</div>
                break;
        }
    }

    /// <summary>
    /// Author: -
    /// </summary>
    const getShareContent = (baseUrl) => {
        if (_state.receipt) {
            let urlEncodedText = _state.receipt.receiptString.map((r, index) => {
                let tempReceipt = r;
                if (index != _state.receipt.receiptString.length - 1) {
                    tempReceipt += '\n=========\n'
                }
                tempReceipt.replace(/\\n/gi, '%0a').replace(/&/g, '');
                return tempReceipt;
            }).join('');

            return urlEncodedText;

            //if (baseUrl) {
                //return `${baseUrl}${encodeURIComponent(urlEncodedText)}`;
            //} else {
            //}
        }
    }

    async function cancelOrder() {
        _dispatch(setBusy());
        let url = ApiUrl._API_CANCEL_MULTIPLE_ORDER;
        let params = {
            header_ids: receipt.ticketXId.length > 1 ? selectedTicket : [receipt.ticketXId[0]]
        }
        await ApiEngine.post(url, params)
            .then((responseJson) => {
                let cancelled = [...cancelledTicket];
                responseJson[ApiKey._API_DATA_KEY].map(t => { cancelled.push(t) });
                setCancelledTicket(cancelled);
                setSelectedTicket([]);
                _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
            }).finally(() => {
                _dispatch(setIdle());
                setModalOpen(!isModalOpen);
            })
    }

    function onSelectedTicket(index) {
        if (selectedTicket.filter(selected => selected == receipt.ticketXId[index]).length) {
            setSelectedTicket(selectedTicket.filter(selected => selected != receipt.ticketXId[index]));
        }
        else {
            setSelectedTicket([...selectedTicket, receipt.ticketXId[index]]);
        }
    }

    return (
        <>
            <div id="betSuccessful">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal text-center">
                            <h3>{t("BET_PLACED")}</h3>
                            {
                                receipt && <>
                                    <p>{t("FOLLOWING_BET_RECEIPT")}</p>
                                    {renderBetStatus(receipt.betStatus)}
                                    <div className="w-50 success-description">
                                        {
                                            receipt.receiptString.map((r, index) => {
                                                return <pre onClick={() => {
                                                    if (!cancelledTicket.includes(receipt.ticketXId[index])) {
                                                        onSelectedTicket(index)
                                                    }
                                                }}
                                                    key={index}
                                                    className="bet-receipt"
                                                    style={{
                                                        color: cancelledTicket.includes(receipt.ticketXId[index]) ? 'red' : '',
                                                        backgroundColor: selectedTicket.includes(receipt.ticketXId[index]) ? '#f2d179' : 'transparent'
                                                    }}>{r}
                                                </pre>
                                            })
                                        }
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <Button onClick={() => _history.push('/')} className="btn btn-lg theme-btn-2 mr-2">{t("RETURN_TO_HOME")}</Button>
                                        <Button onClick={() => _history.push(_state.betUrl)} className="btn btn-lg theme-btn-1">{t("MAKE_ANOTHER_BET")}</Button>
                                    </div>
                                    <div className="text-center mt-2">
                                        <Button onClick={() => _history.push(WebUrl._BET_REPORT, { ticketXId: receipt.ticketXId.join("-") })} className="btn btn-lg theme-btn-2 mr-2">{t("VIEW_BET_REPORT")}</Button>
                                        <Button onClick={() => navigator.share({ text: getShareContent() })} className="btn btn-lg theme-btn-2">{t("SHARE_RECEIPT")}</Button>
                                    </div>
                                    {
                                        cancelledTicket.length != receipt.ticketXId.length && 
                                        <div className="text-center mt-2">
                                            <Button onClick={() => {
                                                if (receipt.ticketXId.length > 1 && selectedTicket.length == 0) {
                                                    _dispatch(showJsonResponseAlert(false, t('PLEASE_SELECT_AT_LEAST_ONE_ORDER_TO_CANCEL')));
                                                }
                                                else {
                                                    setModalOpen(!isModalOpen)
                                                }
                                            }} className="cancel-btn theme-btn-5">{t("CANCEL_ORDER")}</Button>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-brand" isOpen={shareModal} toggle={setShareModal} centered>
                <ModalBody>
                    <strong className="d-block text-center mb-3">{t("SHARE_RECEIPT")}</strong>
                    <div className="btn-share-list">
                        <a target="_blank" className="btn-share btn-share-wa" href={getShareContent(SHARE_PLATFORM.WHATSAPP_SHARE_URL)}>WhatsApp<img src={require('../../assets/img/theme/whatsapp-brands.svg')} /></a>
                        <a target="_blank" className="btn-share btn-telegram" href={getShareContent(SHARE_PLATFORM.TELEGRAM_SHARE_URL)}>Telegram<img src={require('../../assets/img/theme/telegram-brands.svg')} /></a>
                        <CopyToClipboard text={getShareContent(SHARE_PLATFORM.WECHAT_SHARE_URL)}>
                            <a target="_blank" className="btn-share btn-share-wa" href="weixin://">WeChat<img src={require('../../assets/img/theme/wechat-brands.svg')} /></a>
                        </CopyToClipboard>
                    </div>
                    <div className="text-center">
                        <button className="btn theme-btn-2" onClick={() => setShareModal(false)}>{t("CANCEL")}</button>
                    </div>
                </ModalBody>
            </Modal>
            <Modal isOpen={isModalOpen} toggle={() => setModalOpen(!isModalOpen)} className='bettingModal' centered>
                <ModalBody className="text-center">
                    <p className="f-s-20 sfp-bold"> {t("CONFIRM_CANCEL_ORDER")} </p>
                    <p className="f-s-17 theme-text-3 sfp-medium">{t("CONFIRM_CANCEL_ORDER_LINE1")} <br />{t("CONFIRM_CANCEL_ORDER_LINE2")}</p>
                    <div className="d-flex justify-content-center">
                        <Button onClick={() => setModalOpen(!isModalOpen)} className="theme-btn-4 btn btn-md cancel-btn m-r-5">{t("NO")}</Button>
                        <Button onClick={() => {
                            cancelOrder();
                        }} className="theme-btn-1 btn btn-md m-l-5">{t("YES")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default BetSuccessful;
