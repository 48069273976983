import React from 'react';
import classnames from 'classnames';

/// <summary>
/// Author: Chris
/// </summary>
const Input = (props) => {
    const {
        formGroup,
        label,
        error,
        children,
        inline,
    } = props;

    return (
        <div className={classnames({ 'form-group': formGroup }, { 'row': inline })}>
            {label && <label className={inline ? "col-lg-4" : null}>{label}</label>}
            {inline ? <div className="col-lg-8">{children}</div> : children}
            <span className="input-error">{error}</span>
        </div>
    );
};

export default Input;