import React, { useState, useEffect, forwardRef } from 'react';
import classnames from 'classnames';
import BaseInput from './BaseInput';

/// <summary>
/// Author: Chris
/// </summary>
const Input = forwardRef((props, ref) => {
    const {
        formGroup,
        label,
        error,
        inline,
        onClick,
        ...rest
    } = props;
    const [hasError, setHasError] = useState();

    const _inputErrors = classnames(
        'form-control',
        'form-control-lg',
        'm-b-5',
        {
            'has-error': hasError,
        }
    );

    /// <summary>
    /// Author: Chris
    /// </summary>
    useEffect(() => {
        setHasError(error);
    }, [error]);

    return (
        <BaseInput label={label} error={error} formGroup={formGroup} inline={inline}>
            <input ref={ref} {...rest} className={_inputErrors} />
        </BaseInput>
    );
});

Input.defaultProps = {
    formGroup: true,
    inline: false,
}

export default Input;