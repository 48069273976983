import React, { useState, useEffect } from 'react';
import PageNav from '../../../components/header/page-navbar.jsx';
import { Jumbotron, Row, Col, Button, Table } from 'reactstrap';
import { ApiUrl, ApiKey, Images, POOLS, APP_TYPE, POOL_CODE } from '../../../util/Constant.js';
import DatePicker from "react-datepicker";
import { MapBrandsLogoImages, stringIsNullOrEmpty } from '../../../util/Utility.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import classnames from 'classnames';
import { useSelector, useDispatch } from "react-redux";
import { showJsonResponseAlert, setBusy, setIdle } from "../../../redux/AppAction";
import moment from 'moment';
import DateInput from '../../../components/form/DateInput';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import { useTranslation } from 'react-i18next';
import { AppConfiguration } from "read-appsettings-json";
import ApiEngine from '../../../util/ApiEngine';

const ResultView = props => {
    const _dispatch = useDispatch();
    var { t } = useTranslation();

    const [date, setDate] = useState(new Date());
    const [tableData, setTableData] = useState();
    const [result, setResult] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [logo, setLogo] = useState();
    const [displayDrawDate, setDisplayDrawDate] = useState("");
    const [poolList, setPoolList] = useState();

    var _BETTING_BRANDS_SWIPER = [
        { code: POOL_CODE._MAGNUM, imgSrc: Images._MAGNUM_LOGO_SQUARE, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._TOTO, imgSrc: Images._TOTO_LOGO_SQUARE, inversedImgSrc: Images._TOTO_INVERSED_LOGO, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._DAMACAI, imgSrc: Images._DAMACAI_LOGO_SQUARE, inversedImgSrc: Images._DAMACAI_INVERSED_LOGO, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._SG, imgSrc: Images._SG_LOTTO_SQUARE, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._88, imgSrc: Images._88_LOTTO_LOGO_SQUARE, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._STC, imgSrc: Images._STC_LOTTO_LOGO_SQUARE, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._CASH_SWEEP, imgSrc: Images._DA_CASH_WAN_LOGO, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._GD, imgSrc: Images._GD_LOTTO_LOGO, appType: APP_TYPE._NORMAL },
        { code: POOL_CODE._NINELOTTO, imgSrc: Images._NINELOTTO_LOGO, appType: APP_TYPE._NORMAL },
    ];

    const _MAX_DAY_VIEW = 2;

    const brandsOnClickSetHandler = (code) => {
        setTableData(result.find((data) => { return data.brand == code }));
        let selectedPool = poolList.find((pool) => { return pool.code == code });
        setLogo(selectedPool.inversedImgSrc ?? selectedPool.imgSrc)
    }

    useEffect(() => {
        setPoolList(_BETTING_BRANDS_SWIPER.filter(b => b.appType === AppConfiguration.Setting().ApplicationConfig.appType));
    }, []);

    useEffect(() => {
        if (poolList) {
            getResult(true);
        }
    }, [poolList]);

    useEffect(() => {
        if (!stringIsNullOrEmpty(result)) {
            getResult(false);
        }
    }, [date]);

    async function getPastDrawSchedule(action, forceDate = null) {
        let selectedDate = forceDate ?? displayDrawDate.fullDate;

        await ApiEngine.get(`${ApiUrl._API_GET_BETDATE}?day_number=${action == "next" ? _MAX_DAY_VIEW : -_MAX_DAY_VIEW}&selected_date=${selectedDate}`)
            .then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY] && responseJson[ApiKey._API_DATA_KEY].length > 0) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    if ((data.length > 1 || stringIsNullOrEmpty(displayDrawDate)) && !stringIsNullOrEmpty(action)) {
                        data.shift();
                    }
                    setDisplayDrawDate(data[0]);
                    setDate(moment(data[0].fullDate, 'YYYYMMDD').toDate());
                }
            });
    }

    async function getResult(checkLatest) {
        let url = ApiUrl._API_GET_LATEST_RESULT;

        if (checkLatest == false && !stringIsNullOrEmpty(date)) {
            url += `?date=${moment(date).format("YYYYMMDD")}`;
        }

        await ApiEngine.get(url).then((responseJson) => {
                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let data = responseJson[ApiKey._API_DATA_KEY];
                    setResult(data);
                    setTableData(data.find((data) => { return data.brand == poolList[0].code }));

                    let selectedPool = poolList[0];
                    setLogo(selectedPool.inversedImgSrc ?? selectedPool.imgSrc)

                    let sortedResult = data.sort((a, b) => b.drawDate - a.drawDate);

                    if (stringIsNullOrEmpty(displayDrawDate) || !stringIsNullOrEmpty(sortedResult[0].drawDate)) {
                        setDisplayDrawDate({
                            fullDate: sortedResult[0].drawDate,
                            date: moment(sortedResult[0].drawDate, "YYYYMMDD").format("DD/MM"),
                            id: moment(sortedResult[0].drawDate, "YYYYMMDD").format("ddd")
                        })
                    }
                }
                else {
                    _dispatch(showJsonResponseAlert(responseJson[ApiKey._API_SUCCESS_KEY], responseJson[ApiKey._API_MESSAGE_KEY]));
                }
            });
    }

    const _OPTIONS = {
        dots: false,
        nav: true,
        loop: false,
        items: 4,
        navText: ["<i class='fa fa-angle-left' />", "<i class='fa fa-angle-right' />"]
    }

    return (
        <div id="resultPage">
            <PageNav pageTitle={t('VIEW_RESULTS')} showDate={false} />
            <div className="bg-betting-history">
                <div className="bg-1"></div>
                <div className="bg-2"></div>
                <div className="bg-3"></div>
            </div>
            <div className="set-page-content">
                <div className="page-container container">
                    <div className="page-layout-set-vertical">
                        <div className="page-layout-set-horizontal">
                            <Jumbotron className="gold-jumbotron d-flex datefilter-wrapper">
                                <div>
                                    <img src={Images._CLOCK_ICON} />
                                    <div className="date-wrapper">
                                        <span className="m-0">{t('DRAW_DATE')}</span>
                                        <DatePicker
                                            dateFormat="dd/MM/yyyy"
                                            selected={date}
                                            onChange={(d) => { setDate(d) }}
                                            placeholderText="dd/mm/yyyy"
                                            maxDate={new Date()}
                                            popperPlacement="bottom-center"
                                            customInput={<DateInput />}
                                        />
                                    </div>
                                </div>
                                <Button className="theme-btn-2 pull-right" onClick={() => { getResult(false) }}>{t('APPLY')}</Button>
                            </Jumbotron>

                            {
                                !stringIsNullOrEmpty(displayDrawDate) &&
                                <div className="d-flex justify-content-between align-items-center" style={{ marginBottom: '.7rem' }}>
                                    <button className="btn btn-round" onClick={() => { getPastDrawSchedule("prev") }}>
                                        <i className="las la-angle-left mt-0 brand-color"></i>
                                    </button>
                                    <div className="line-height-1 text-center">
                                        {displayDrawDate.date}
                                        <div className="text-uppercase">{displayDrawDate.id}</div>
                                    </div>
                                    <button className="btn btn-round" onClick={() => { getPastDrawSchedule("next") }}>
                                        <i className="las la-angle-right mt-0 brand-color"></i>
                                    </button>
                                </div>
                            }

                            <div className="result-container">
                                <Table borderless={true} className="mb-0">
                                    <thead>
                                        <tr>
                                            <th colSpan="5">
                                                <div className="d-flex justify-content-between">
                                                    <div className="pool-detail-wrapper">
                                                        <img src={logo} width={50} height={50} style={{ padding: '.3rem' }} />
                                                        <span className="p-l-10 pool-name">{tableData?.brand ? POOLS.find((p) => { return p.code == tableData?.brand }).name : ""}</span>
                                                    </div>
                                                    <div className="sfp-regular draw-date d-flex align-items-center text-right mr-2">
                                                        {t('DRAW_DATE')} : <br></br>
                                                        {!stringIsNullOrEmpty(tableData?.drawDate) ? moment(tableData?.drawDate, "YYYYMMDD").format('DD/MM/yyyy') : "N/A"}
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-center" colSpan='3'>1st Prize</td>
                                            <td className="text-left gold-text" colSpan='2'>{tableData?.topPrize[0]}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center" colSpan='3'>2nd Prize</td>
                                            <td className="text-left gold-text" colSpan='2'>{tableData?.topPrize[1]}</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center" colSpan='3'>3rd Prize</td>
                                            <td className="text-left gold-text" colSpan='2'>{tableData?.topPrize[2]}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <div class="special-wrapper result-list-wrapper">
                                                    {
                                                        tableData?.specialPrize.map((arr, index) => {
                                                            return <div class="specialPrize">{arr}</div>
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan="5">
                                                <div class="consolation-wrapper result-list-wrapper">
                                                    {
                                                        tableData?.consolationPrize.map((arr, index) => {
                                                            return <div class="consolationPrize">{arr}</div>
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="swiper-footer m-t-20">
                <div className="pool-owl">
                    {
                        poolList &&
                        <OwlCarousel className="owl-carousel owl-theme" options={_OPTIONS}>
                            {
                                poolList.map((item, index) => {
                                    return <div
                                        onClick={() => { setActiveIndex(index); brandsOnClickSetHandler(item.code) }}
                                        key={index}
                                        data-value={index}
                                        className={classnames(
                                            "swiper-brand-icon-wrapper text-center",
                                            { 'active': activeIndex == index }
                                        )}>
                                        <img className="brand-icon" src={item.imgSrc}></img>
                                    </div>
                                })
                            }
                        </OwlCarousel>
                    }
                </div>
            </div>
        </div >
    )
}

export default ResultView;