import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { PageSettings } from './../config/page-settings.js';
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WebUrl } from '../util/Constant.js';
import { performLogin } from '../redux/AuthAction.js';
import Input from '../components/form/Input.js';

/// <summary>
/// Author: -
/// </summary>
const Login = props => {
    const { t, i18n } = useTranslation();
    let _history = useHistory();
    var _context = useContext(PageSettings);
    var _dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.authState);
    const { control, handleSubmit, errors, setValue } = useForm();

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        _context.handleSetPageHeader(false);
        _context.handleSetPageContentFullWidth(true);

        return (() => {
            _context.handleSetPageHeader(true);
            _context.handleSetPageContentFullWidth(false);
        });
    }, []);

    /// <summary>
    /// Author: -
    /// </summary>
    useEffect(() => {
        if (isLoggedIn) {
            _history.push(WebUrl._HOME);
        }
        else {
            setValue('username', localStorage.getItem("_u"));
        }
    }, [isLoggedIn]);

    /// <summary>
    /// Author: -
    /// </summary>
    const submitForm = async (data, e) => {
        await _dispatch(performLogin(data.username, data.password));
    }

    return (
        <div className="login-container">
            <div class="topbar">
                
            </div>
            <div class="login-title">
                <strong class="text-theme">Welcome</strong><br />
            </div>
            <div className="login-form">
                <form className="margin-bottom-0" onSubmit={handleSubmit(submitForm)}>
                    <Controller
                        control={control}
                        name="username"
                        render={({ onChange, value, name }) => (
                            <Input
                                name={name}
                                className="form-control-lg"
                                value={value}
                                onChange={value => {
                                    onChange(value);
                                }}
                                placeholder="Username"
                                type="text"
                            />
                        )}
                        rules={{ required: true }}
                    />
                    <Controller
                        control={control}
                        name="password"
                        render={({ onChange, value, name }) => (
                            <Input
                                name={name}
                                className="form-control-lg"
                                value={value}
                                onChange={value => {
                                    onChange(value);
                                }}
                                placeholder="Password"
                                type="password"
                            />
                        )}
                        rules={{ required: true }}
                    />

                    <div className="login-buttons">
                        <button type="submit" className="btn btn-success btn-block btn-lg">Login</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default withRouter(Login);