import { _LOGIN_SUCCESS, _LOGIN_FAILED, _LOGOUT } from './AuthAction';

const _INITIAL_STATE = {
    userData: localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {},
    isLoggedIn: false,
    loginMessage: "",
    loginStateInitialized: false,
};

/// <summary>
/// Author : -
/// </summary>
export default (state = _INITIAL_STATE, action) => {
    switch (action.type) {
        case _LOGIN_SUCCESS:
            return {
                ...state,
                userData: action.userData,
                isLoggedIn: true,
                loginMessage: "",
                loginStateInitialized: true
            }
            break;
        case _LOGIN_FAILED:
            return {
                ...state,
                loginMessage: action.message,
                isLoggedIn: false,
                userData: {},
                loginStateInitialized: true
            }
            break;
        case _LOGOUT:
            return {
                ...state,
                userData: {},
                isLoggedIn: false,
                loginMessage: "",
                loginStateInitialized: true,
            };
            break;
        default:
            return state;
            break;
    }
};
